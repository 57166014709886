import React from 'react';
import { login_auth_url, client_id, redirect_uri } from '../../api/config';
import { Button } from 'reactstrap';
import '../../scss/index.scss';

export function LoginAuth() {
	const oauth = `${login_auth_url}?client_id=${client_id}&redirect_uri=${encodeURIComponent(
		redirect_uri
	)}`;

	const onClick = () => {
		window.location.href = oauth;
	};

	return (
		<>
			<div className="d-flex flex-column align-items-center justify-content-center custom-height">
				<div className="p-2">Necesita iniciar sesión para continuar</div>
				<div className="p-2">
					<Button color="primary" onClick={onClick}>
						Login
					</Button>
				</div>
			</div>
		</>
	);
}
