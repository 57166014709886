import React, { useEffect } from 'react';
import './NotFound.scss';
import { useNavigate } from 'react-router-dom';
import fullLogo from '../../img/ucc-logo-completo.png';

export default function NotFound(error) {
	const navigate = useNavigate();

	useEffect(() => {
		if (error === true) {
			navigate('/notFound');
		}
	}, []);

	return (
		<>
			<div className="containerError">
				<div className="vertical-center position-absolute">
					<div className="row">
						<div className="col-md-6 border-custome">
							<div className="d-flex justify-content-center">
								<img className="ucclogo" alt="ucclogo" src={fullLogo} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="d-flex justify-content-center">
								<h1 className="error-title mt-3">404</h1>
							</div>
							<div className="d-flex justify-content-center">
								<p className="error-description">
									No pudimos encontrar la página que estás buscando.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
