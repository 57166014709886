import LayoutBasic from '../layouts/LayoutBasic';
import LayoutAuth from '../layouts/LayoutAuth';

import Auth from '../pages/Auth/Auth';
import SignIn from '../pages/Auth/SignIn';

import Home from '../pages/Home';
import Profile from '../pages/Profile';
import Example from '../pages/Example/Example';
import ListadoProyectos from '../pages/ListadoProyectos/ListadoProyectos';

import TableroProyectos from '../pages/TableroProyectos/TableroProyectos';
import ListadoActividades from '../pages/ListadoActividades/ListadoActividades';
import TemasProyecto from '../pages/TemasProyecto/TemasProyecto';

import NotFound from '../pages/NotFound/NotFound';

import Unidad from '../pages/Unidad/Unidad';
import Material from '../pages/Material/Material';
import Interaccion from '../pages/Interaccion/Interaccion';
import Competencia from '../pages/Competencia/Competencia';
import Equivalencia from '../pages/Equivalencia/Equivalencia';
import AsociarUnidades from '../pages/AsociarUnidades/AsociarUnidadesA';

/* Recurso */
import CrearEditarRecurso from '../pages/CrearEditarRecurso/CrearEditarRecurso';
import Recurso from '../pages/Recurso/Recurso';

/*Proyecto*/
import Proyecto from '../pages/Proyecto/Proyecto';
import ProyectoActividad from '../pages/Proyecto/ProyectoActividad';
import ProyectoDetalle from '../pages/Proyecto/ProyectoDetalle';
import ProyectoModulo from '../pages/Proyecto/ProyectoModulo';

/*Enfasis*/
import Enfasis from '../pages/Enfasis/Enfasis';

/*Proposito*/
import Proposito from '../pages/Proposito/Proposito';

/*Actividad*/
import Actividad from '../pages/Actividad/Actividad';

/*Tipo*/
import Tipo from '../pages/Tipo/Tipo';
/*Dificultad*/
import Dificultad from '../pages/Dificultad/Dificultad';

import checkRouteRole from '../../src/config/rolesRoutes';

let routesHandler = checkRouteRole().map(el => el.route);

const routesAuth = [
	{
		path: '/auth',
		layout: LayoutAuth,
		component: Auth,
	},
	{
		path: '/login',
		layout: LayoutBasic,
		component: SignIn,
	},
	{
		path: '/notFound',
		layout: LayoutBasic,
		component: NotFound
	},
	{
		path: '/*',
		layout: LayoutBasic,
		component: NotFound,
	},
];

const routesClient = [
	{
		path: '/',
		layout: LayoutBasic,
		component: Home,
	},
	{
		path: '/profile',
		layout: LayoutBasic,
		component: Profile,
	},
	{
		path: '/unidades',
		layout: LayoutBasic,
		component: Unidad,
	},
	{
		path: '/asociarUnidades',
		layout: LayoutBasic,
		component: AsociarUnidades,
	},
	{
		path: '/material',
		layout: LayoutBasic,
		component: Material,
	},
	{
		path: '/interacciones',
		layout: LayoutBasic,
		component: Interaccion,
	},
	{
		path: '/competencia',
		layout: LayoutBasic,
		component: Competencia,
	},
	{
		path: '/equivalencia',
		layout: LayoutBasic,
		component: Equivalencia,
	},
	{
		path: '/proyecto',
		layout: LayoutBasic,
		component: Proyecto,
	},
	{
		path: '/proyectoactividad',
		layout: LayoutBasic,
		component: ProyectoActividad,
	},
	{
		path: '/example',
		layout: LayoutBasic,
		component: Example,
	},
	{
		path: '/proyectos',
		layout: LayoutBasic,
		component: ListadoProyectos,
	},
	{
		path: '/listadoActividades/:id',
		layout: LayoutBasic,
		component: ListadoActividades,
	},
	{
		// path: '/tableroProyectos/:id',
		path: '/tableroProyectos',
		layout: LayoutBasic,
		component: TableroProyectos,
	},
	{
		path: '/temasProyecto',
		layout: LayoutBasic,
		component:  TemasProyecto 
	},
	{
		path: '/proyectoDetalle',
		layout: LayoutBasic,
		component: ProyectoDetalle,
	},
	{
		path: '/proyectomodulo',
		layout: LayoutBasic,
		component: ProyectoModulo,
	},
	{
		path: '/enfasis',
		layout: LayoutBasic,
		component: Enfasis,
	},
	{
		path: '/proposito',
		layout: LayoutBasic,
		component: Proposito,
	},
	{
		path: '/actividad',
		layout: LayoutBasic,
		component: Actividad,
	},
	{
		path: '/tipo',
		layout: LayoutBasic,
		component: Tipo,
	},
	{
		path: '/dificultad',
		layout: LayoutBasic,
		component: Dificultad,
	},
	{
		path: '/recursos',
		layout: LayoutBasic,
		component: Recurso,
	},
	{
		path: '/crearEditarRecurso/*',
		layout: LayoutBasic,
		component: CrearEditarRecurso,
	},
	{
		path: '/notFound',
		layout: LayoutBasic,
		component: NotFound
	}
];

const routeNotFound = [
	{
		path: '*',
		layout: LayoutBasic,
		component: NotFound,
	},
];

let routesClienFinal = routesClient.filter(el => {
	return routesHandler.includes(el.path);
});

// const routes = [...routesAuth, ...routesClient, ...routeNotFound];
const routes = [...routesAuth, ...routesClienFinal, ...routeNotFound];

export default routes;
