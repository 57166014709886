import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAuth from '../hooks/useAuth';
import { LoginAuth } from '../components/login/LoginAuth';
import NavbarGeneral from '../components/header/Nav';
import Container from '@mui/material/Container';
import NotFound from '../pages/NotFound/NotFound';
import {
	routes_role_admin_plan,
	routes_role_conte_plan,
} from '../../src/config/rolesRoutes';
import { ArrowBackIosNewSharp } from '@mui/icons-material';

export default function LayoutBasic(props) {
	const { children } = props;
	const { user, isLoading } = useAuth();
	const [errorFound, setErrorFound] = useState(false);
	const [path, setPath] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		const arrRoutes = [];
		arrRoutes.push('/login');

		routes_role_admin_plan.map(e => {
			arrRoutes.push(e.route);
		});

		routes_role_conte_plan.map(e => {
			arrRoutes.push(e.route);
		});

		if (arrRoutes.includes(window.location.pathname)) {
			if (!user && !arrRoutes.includes(window.location.pathname)) {
				// login
				navigate('/login');
				setErrorFound(false);
			}
		} else {
			navigate('/notFound');
			setErrorFound(true);
		}
	}, []);

	if (!user && !isLoading) {
		return (
			<>
				{!errorFound ? (
					<LoginAuth />
				) : (
					<div className='container mt-5'>
						<NotFound error={errorFound} />
					</div>
				)}
			</>
		);
	}

	if (user && !isLoading) {
		return (
			<>
				<React.Fragment>
					<NavbarGeneral></NavbarGeneral>
					<Container maxWidth="lg">
						<div>{children}</div>
					</Container>
				</React.Fragment>
			</>
		);
	}
	return null;
}
